<template>
  <div class="share-view">
    <img src="./image/bg.png" alt="" class="hide-img">
    <p class="hide-img">TOY探索更多可能</p>
    <div class="show-con">
      <div class="show-list" @click="linkTo(1)">
        <div class="list-left">桌面端产品展示</div>
        <div class="list-right">
          <img src="./image/list1.png" alt="">
        </div>
      </div>
      <div class="show-list" @click="linkTo(2)">
        <div class="list-left">移动端产品展示</div>
        <div class="list-right">
          <img src="./image/list2.png" alt="">
        </div>
      </div>
      <div class="show-list" @click="linkTo(3)">
        <div class="list-left">小程序产品展示</div>
        <div class="list-right">
          <img src="./image/list3.png" alt="">
        </div>
      </div>
      <div class="show-list" @click="linkTo(4)">
        <div class="list-left">
          <div class="top">请先前往<span>www.TOY.space</span></div>
          <div class="bottom">体验TOY小程序、APP及桌面应用</div>
        </div>
        <div class="list-right">
          <img src="./image/list4.png" alt="">
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import {useWxShare} from "../../assets/wxShare"
export default {
  name: "video",
  setup(){
    const router = useRouter()
    const linkTo = (e)=>{
      if(e == 1){
        router.push({
          path:'/video/toypcnew'
        })
      }
      if(e == 2){
        router.push({
          path:'/video/totm'
        })
      }
      if(e == 3){
        router.push({
          path:'/video/miniapp'
        })
      }
      if(e == 4){
        window.open('https://www.toy.space')
      } 
    }
    return{
      linkTo
    }
  },
  data() {
    return {
      
    };
  },
  mounted() {
    // useWxShare({
    //   title: 'TOY',
    //   desc: '探索更多可能',
    //   imgUrl: 'https://toyspace-prod.oss-cn-beijing.aliyuncs.com/public/mini/mapp_share.png',
    // })
  },
  methods: {
 
  },
};
</script>
<style lang="scss">
  .share-view{
    width: 100%;
    height: 100vh;
    background: url('./image/bg.png') no-repeat;
    background-size: cover;
  }
  .show-con{
    position: fixed;
    bottom: 100px;
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
  }
  .show-list{
    width: 100%;
    height: 68px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(63, 63, 63, 0.31);
    backdrop-filter: blur(2px);
    border-radius: 8px;
    color: #F3F3F3;
    font-size: 14px;
    margin-bottom: 12px;
  }
  .list-right{
    width: 54px;
    height: 54px;
    border-radius: 8px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .list-left{
    text-align: left;
  }
  .top{
    margin-bottom: 10px;
    span{
      color: #597EF7;
      padding-left: 6px;
    }
  }
  .hide-img{
    display: none;
  }
</style>
